<template>
  <q-item
    clickable
    v-ripple
    class="row items-center"
    :class="options.className"
    @click="handleOpen"
  >
    <q-item-section>
      {{ helper.title }}
    </q-item-section>

    <q-dialog
      v-model="isOpen"
      persistent
    >
      <q-card style="min-width: 98vw;">
        <q-card-section class="row items-center q-py-sm">
          <div class="text-h6 col">
            {{ helper.title }}
          </div>

          <q-btn
            v-close-popup
            icon="close"
            flat
            round
            dense
          />
        </q-card-section>

        <q-card-section class="relative">
          <img
            v-if="helper.cover"
            :src="helper.cover"
            style="position: absolute; z-index: 0; top: 0; right: 0; max-width: 100%; height: 100%; object-fit: contain; opacity: .3;"
          >

          <div class="text-subtitle2">
            {{ helper.description }}
          </div>
        </q-card-section>

        <q-card-actions align="center">
          <q-btn
            color="positive"
            :label="$t('Got it!')"
            @click="handleClose"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-item>
</template>

<script>
export default {
  name: 'HelperInstructionItem',
  props: {
    helper: {
      type: Object,
      default () {
        return { message: '', state: 'inactive' }
      }
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    options () {
      return {
        icon: 'list',
        color: 'positive',
        className: ''
      }
    }
  },
  methods: {
    handleOpen () {
      this.isOpen = true
    },
    handleClose () {
      this.isOpen = false
    }
  }
}
</script>
