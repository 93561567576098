<template>
  <div class="q-px-sm q-py-xs rounded bg-light-blue-2">
    {{ helper.message }}
  </div>
</template>

<script>
export default {
  name: 'HelperMessage',
  props: {
    helper: {
      type: Object,
      default () {
        return { message: '', state: 'inactive' }
      }
    }
  }
}
</script>
