<template>
  <div class="row q-pb-sm text-white justify-center" style="margin: 0 -4px">
    <div v-show="hasContent" class="q-pa-xs col-4 col-md-2">
      <portal-target name="tiles" @change="handleChange" />
    </div>

    <div v-for="tile in schema" :key="tile.id" class="col-4 col-md-2 q-pa-xs">
      <div
        class="text-center rounded row items-center justify-center"
        :style="`background: ${getColor(tile)}; min-height: 66px;`"
        @click="onChanged(tile.id, tile.onChanged, tile.value, tile.disabled)"
      >
        <div class="q-px-sm">
          <q-icon :name="tile.icon" size="2rem" />

          <div class="text-caption">
            {{ tile.label }}
          </div>
        </div>
      </div>
    </div>

    <div v-show="hasAdditionalContent" class="q-pa-xs col-4 col-md-2">
      <portal-target name="additional-tiles" @change="handleAdditionalChange" />
    </div>

    <div class="q-pa-xs col-4 col-md-2">
      <div
        class="text-center rounded row items-center justify-center"
        :style="`background: ${getColor({ value: true })}; min-height: 66px;`"
        @click="$refs.helper.open"
      >
        <div class="q-px-sm row items-center justify-center">
          <div class="col-12 q-pt-xs">
            <img
              style="
                max-width: 100%;
                height: 100%;
                max-height: 36px;
                object-fit: contains;
              "
              src="/assets/terminal/manager.png"
            />
          </div>

          <div class="text-caption">
            {{ $t("Guide") }}
          </div>
        </div>
      </div>
    </div>

    <helper ref="helper" />
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Components
import Helper from './Helper.vue'

export default {
  name: 'QuickSettings',
  emits: ['submit'],
  components: {
    Helper
  },
  props: {
    schema: {
      type: Array,
      required: true,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      hasContent: false,
      hasAdditionalContent: false
    }
  },
  computed: {
    ...mapGetters([
      'helpers'
    ])
  },
  methods: {
    handleAdditionalChange (hasContent) {
      this.hasAdditionalContent = hasContent
    },
    handleChange (hasContent) {
      this.hasContent = hasContent
    },
    getColor (tile) {
      if (tile.disabled) {
        return 'rgba(0, 0, 0, 0.2)'
      }

      return tile.value
        ? 'rgba(0, 0, 0, 0.9)'
        : 'rgba(0, 0, 0, 0.4)'
    },
    onChanged (id, fn, value, disabled) {
      if (disabled) {
        return
      }

      if (fn) {
        fn(value)
      }

      this.$eventBus.update('menu', { isOpen: false })
      this.$emit('submit', id, value)
    }
  }
}
</script>
