<template>
  <div>
    <!-- <div
      v-if="helpers.length > 0"
      class="fixed q-pa-lg row items-center"
      :style="`top: ${topBtn}px; right: ${right}px; transition: top .3s;`"
    >
      <div class="relative">
        <div
          v-if="lastHelper"
          style="position: absolute; top: 5px;"
          :style="dimensions"
        >
          <component
            :is="messages[lastHelper.type] || 'helper-message'"
            v-show="lastHelper.state === 'active'"
            :helper="lastHelper"
            class="col q-mr-sm"
          />
        </div>

        <div
          class="btn-pulse row items-center justify-center"
          v-touch-pan.prevent.mouse="handleTouch"
          @click="open"
        />
      </div>
    </div> -->

    <q-dialog v-model="isOpen">
      <q-card style="min-width: 94vw;">
        <q-card-section class="row items-center q-pt-sm q-pb-none">
          <q-space />

          <div class="text-h6">
            {{ title }}
          </div>

          <q-space />

          <q-btn
            v-close-popup
            icon="close"
            flat
            round
            dense
          />
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-list bordered separator>
            <component
              :is="items[helper.type] || 'helper-message-item'"
              v-for="helper in helpers"
              :key="helper.id"
              :helper="helper"
            />
          </q-list>
        </q-card-section>

        <q-card-section v-if="shortcuts.length > 0" class="q-pt-none q-px-sm">
          <div class="text-subtitle1 q-mb-sm q-px-sm text-center">
            {{ $t('Shortcuts (click to copy the code)') }}
          </div>

          <div class="row justify-center">
            <q-badge
              v-for="item in shortcuts"
              :key="item.route"
              class="q-ma-xs"
              @click="handleCopy(item)"
            >
              <div class="text-center">
                <div class="text-subtitle2">
                  {{ item.name }}

                  <q-icon name="attach_file" size="10px" />
                </div>

                <div
                  v-if="item.caption"
                  class="text-caption"
                >
                  {{ item.caption }}
                </div>
              </div>
            </q-badge>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import HelperMessageItem from './HelperMessageItem.vue'
import HelperInstructionItem from './HelperInstructionItem.vue'
import HelperInstruction from './HelperInstruction.vue'
import HelperMessage from './HelperMessage.vue'
import HelperErrorMessage from './HelperErrorMessage.vue'
import HelperWarningMessage from './HelperWarningMessage.vue'

// Utils
// import { getOption } from '../../routes'

// Quasar
import { copyToClipboard } from 'quasar'

const height = 50
const btnSize = 130

function getDefaultPosition () {
  return window.innerHeight - height
}

export default {
  name: 'Helper',
  components: {
    HelperMessage,
    HelperErrorMessage,
    HelperMessageItem,
    HelperWarningMessage,
    HelperInstruction,
    HelperInstructionItem
  },
  data () {
    return {
      messages: {
        error: 'helper-error-message',
        warning: 'helper-warning-message',
        instruction: 'helper-instruction'
      },
      items: {
        instruction: 'helper-instruction-item'
      },
      topBtn: getDefaultPosition() - btnSize,
      right: window.innerWidth - 100,
      isOpen: false,
      hasContent: false,
      timeout: null,
      delay: 5000,
      lastTop: getDefaultPosition() - btnSize
    }
  },
  computed: {
    ...mapGetters([
      'helpers',
      'lastHelper',
      'shortcuts'
    ]),
    title () {
      // const option = getOption(this.$route.path)

      // if (option) {
      //   return this.$t(option.title)
      // }

      return this.$t('To Do')
    },
    dimensions () {
      return this.right > (window.innerWidth / 2) - 100
        ? { left: '90px', width: `${this.right}px`, maxWidth: '300px' }
        : { right: '90px', width: `${window.innerWidth - this.right - 120}px`, maxWidth: '300px' }
    }
  },
  methods: {
    ...mapMutations([
      'hideLastHelper',
      'addNotification',
      'addErrorNotification'
    ]),
    handleCopy (item) {
      return copyToClipboard(item.name)
        .then(() => {
          this.addNotification('Copied')
        })
        .catch(() => {
          this.addErrorNotification('Can not copy')
        })
    },
    open () {
      this.isOpen = true
    },
    // refresh () {
    //   this.isOpen = false

    //   const top = getDefaultPosition() - btnSize

    //   if (this.topBtn > top) {
    //     this.topBtn = top

    //     if (this.topBtn < 0) {
    //       this.topBtn = 50
    //     }
    //   } else if (this.lastTop < top) {
    //     this.topBtn = this.lastTop
    //   }

    //   if (this.right > window.innerWidth) {
    //     this.right = window.innerWidth - btnSize
    //   }
    // },
    // handleTouch (e) {
    //   requestAnimationFrame(() => {
    //     this.hideLastHelper()

    //     this.topBtn = e.position.top - 80
    //     this.right = window.innerWidth - e.position.left - 80
    //     this.$eventBus.publish('helper-move', e)

    //     if (this.right < 0) {
    //       this.right = -20
    //     } else if (this.right >= window.innerWidth - 100) {
    //       this.right = window.innerWidth - 100
    //     }

    //     if (this.topBtn < 0) {
    //       this.topBtn = -20
    //     } else if (this.topBtn >= getDefaultPosition() - btnSize) {
    //       this.topBtn = getDefaultPosition() - btnSize
    //     }

    //     this.lastTop = this.topBtn
    //   })
    // }
  }
}
</script>
