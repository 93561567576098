<template>
  <q-item
    clickable
    v-ripple
    class="row items-center"
    :class="options.className"
  >
    <q-item-section>
      {{ helper.message || helper.title }}
    </q-item-section>
  </q-item>
</template>

<script>
export default {
  name: 'HelperMessageItem',
  props: {
    helper: {
      type: Object,
      default () {
        return { message: '', state: 'inactive' }
      }
    }
  },
  computed: {
    options () {
      if (this.helper.type === 'error') {
        return {
          icon: 'notification_important',
          color: 'negative',
          className: ''
        }
      }

      if (this.helper.type === 'warning') {
        return {
          icon: 'info',
          color: 'warning',
          className: ''
        }
      }

      return {
        icon: 'list',
        color: 'positive',
        className: ''
      }
    }
  }
}
</script>
